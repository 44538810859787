.team{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    font-size: 4em;
    text-align: center;
    margin-top: 60px;
}

.image{
    margin-top: 100px;
    text-align: center;
    padding-bottom: 100px;
}

.flex-cont{
    justify-content: center;
    align-items: center;
}

.imcol{
    margin: 20px 0px 50px;
}

.title-heading{
    font-family: 'Archivo Black';
    font-weight: bold;
    color: white;
    font-size: 3.2em;
    margin-bottom: 20px;
}

.title-name{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 15px;
    color:hsl(0, 0%, 90%);
    font-size: 1.7em;
}

.er{
    margin-top: 30px;
    margin-bottom: 60px;
}

.team-member{
    border-radius: 50%;
    border: 1px hsl(0, 0%, 30%) solid;
    width: 150px;
    height: 150px;
}

