.p img{
  opacity: 50%;
}

.p img:hover{
  opacity: 100%;
}

.navigation ul li.active .icon img{
  opacity: 100%;
}


.navigation ul li a .icon:hover + .text,
.navigation ul li:hover a .text {
  opacity: 1;
  transform: translateY(20px);
}
/* Hamburger Menu Styles */
.hamburger-menu {
  display: none; /* Hidden by default */
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}

.hamburger-menu img {
  width: 40px;
  height: 40px;
}

/* Nav Menu Styles */
.nav-menu {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.nav-menu.active {
  display: flex; /* Show when active */
}

.nav-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
  font-weight: 500;
}


ion-icon {
  pointer-events: none;
 }


/* Responsive Styles */
@media screen and (max-width: 820px) {
  

  .hamburger-menu {
    display: flex; /* Show the hamburger menu on small screens */
  }

  .navigation ul {
    display: none !important;
  }
}
