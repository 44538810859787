/* *{
  margin: 0
} */

.img-change-btn-left{
  position: absolute;
  top: 50%; 
  left: 5%;
  background: transparent;
  border: None;
}
.img-change-btn-left:hover{
  transform: scale(1.2);
}

.img-change-btn-right{
  position: absolute;
  top: 50%; 
  right: 5%;
  background: transparent;
  border: None
}
.img-change-btn-right:hover{
  transform: scale(1.2);
}
.img-btn{
  width: 25px;
  height: 25px;
  cursor: 'pointer' 
}

.close-btn{
  position: absolute;
  top: 10px;
  right: 20px;
  background: transparent;
  border: None
}
.close-btn:hover{
  transform: scale(1.2);
}

/* Loading overlay for images only */
.loading-overlay {
  position: fixed;
  top: 100px; /* Allow space for the Navbar */
  left: 0;
  width: 100%;
  height: calc(100vh - 100px); /* Adjust height to leave the navbar visible */
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-overlay h2 {
  font-size: 2rem;
  color: #333;
}

/* Hide the gallery while loading */
.hidden-gallery {
  display: none;
}

/* Adjust gallery styles */
.gallery {
  display: block;
  padding: 10px; /* Add padding for top/bottom/left/right */
  margin: 10px; /* Add margins around the gallery */
  box-sizing: border-box; /* Include padding in width/height calculations */
}

/* Ensure images have some spacing */
.gallery img {
  margin-bottom: 10px; /* Add spacing between images */
  border-radius: 5px; /* Optional: add rounded corners to images */
}



.section-heading{
  font-family: 'Planer';
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  padding: 2%;
}