.custom-card {
  max-width: 250px;
  margin: 0 auto;
  background-color: #0d0d13;
  color: white;
  position: relative; /* Needed for pseudo-element positioning */
  overflow: hidden; /* Ensures that the glossy effect stays within the card */
  transition: transform 0.3s ease-in-out;
}

.custom-card:hover {
  transform: scale(1.05); /* Slight zoom effect */
}

.custom-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.19); /* Glossy effect color */
  transform: rotate(45deg);
  transition: top 0.4s ease, left 0.3s ease;
}

.custom-card:hover::before {
  top: 100%;
  left: 100%;
}

.card-body .num-text {
  padding-top: 10px;
  font-family: "Archivo Black";
  font-size: 3rem;
  font-weight: bold;
}

.card-body .p-text {
  font-weight: lighter;
  font-size: 1.1rem;
}
