html {
  scroll-behavior: smooth;
}


.sponsors-container {
  padding: 20px;
  margin: 20px auto;
  border-radius: 15px;
  max-width: 90%;
  /* background-color: rgba(250,250,250,0.1); */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


/* Style each row of sponsor images */
.sponsor-row {
  display: flex;
  justify-content: center; /* Centers the images in each row */
  margin-bottom: 10px;
  flex-wrap: wrap;
}


.fle{
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.fle img{
  flex: 1; /* Allow the image to take up less space */
  width: 100%; /* Make the image responsive */
  max-width: 360px; /* Set a maximum width for the image */
  height: auto; 
}
/* Default style for images */
.sponsor-image {
  width: 12.75rem; /* Default size for large screens */
  height: auto;
  margin: 5px;
  object-fit: contain;
  transition: transform 0.3s;
}

.small-image{
  width: 8.5rem;
}

.sponsor-image:hover {
  transform: scale(1.1);
}




/* Sponsors.css */
.collaborate-container{
  padding: 60px;
  margin: 20px auto;
  border-radius: 15px;
  max-width: 75%;
  background-color: rgba(250,250,250,0.5); 
  /* background-image: url("../../assets/pr.png");
  opacity: 0.95;  */
  /* background-repeat: no-repeat;
  background-size: 100%; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.collaborators-form-container {
  padding: 20px;
  
}

.collaborators-form {
  display: flex;
  flex: 2;
  flex-direction: column;
  
}

.form-group {
  margin-top: 30px;
  margin-bottom: 20px;
}

.two {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}


.form-group label {
  font-weight: bold;
}

.input-field {
  padding: 16px; /* Optional: Add padding */
  width: 100%; /* Optional: Set a specific width */
  border: 2px solid #eaedef; 
  font-family: Planer;
  color: rgba(35, 31, 32, .7);
  font-weight: 600;
  border-top-left-radius: 15px; /* Round the top-left corner */
  border-bottom-left-radius: 15px; /* Round the bottom-left corner */
  border-top-right-radius: 15px; /* Round the top-right corner */
  border-bottom-right-radius: 15px; /* Round the bottom-right corner */
  background-color: #eaedef;
}

@media (max-width: 1002px) { /* Smaller screens (like tablets) */
  .sponsor-row {
    flex-wrap: wrap; /* Wrap images to the next line on smaller screens */
  }
  .sponsor-image {
    width: 6.75rem; /* Smaller size for smaller screens */
  }
  .small-image{
    width: 4.5rem;
    height: auto;
  }
}


@media (max-width: 786px) {
  .fle img{
    display: none; /* Hide the image */
  }

  .fle {
    flex-direction: column; /* Stack the form vertically */
  }
}

@media (max-width: 702px) { /* Smaller screens (like tablets) */
  .sponsor-row {
    flex-wrap: wrap; /* Wrap images to the next line on smaller screens */
  }
  .sponsor-image {
    width: 5.75rem; /* Smaller size for smaller screens */
  }
  .small-image{
    width: 3.8rem;
  }
}



@media (max-width: 605px) { /* Very small screens (like phones) */
  .sponsor-row {
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center each image */
  }
  .sponsor-image {
    width: 100%; /* Make the image take up the full width */
    max-width: 300px; /* Optional: Limit the max width of each image */
    padding-bottom: 20px;
  }
  .small-image{
    max-width: 200px;
  }
}

@media (max-width: 503px) { /* Very small screens (like phones) */
  .sponsor-image {
    width: 100%; /* Ensure image takes full width */
    max-width: 250px; /* Optional: Reduce max width for smaller screens */
  }
  .small-image{
    max-width: 150px;
  }
}


/* Very small screens (phones) */
@media (max-width: 480px) {
  .form-group {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .two{
    display: block;
  }
  .input-field {
    width: 100%; /* Full width on small screens */
    max-width: none; /* Remove the max-width */
    font-size: 14px; /* Adjust font size */
  }

  .submit-button {
    width: 100%; /* Full width for button */
    padding: 12px; /* Increase padding */
    font-size: 14px; /* Adjust button text size */
  }

  .collaborate-container{
    padding: 40px;
    max-width: 85%;
  }
}

/* Very small phones */
@media (max-width: 360px) {
  .input-field {
    font-size: 12px; /* Further reduce font size */
  }

  .submit-button {
    font-size: 12px;
  }
  .collaborate-container{
    padding: 30px;
    max-width: 95%;
  }
}
