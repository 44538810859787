@font-face {
  font-family: 'Rogan';
  src: url('./assets/Fonts/Rogan.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Planer';
  src: url('./assets/Fonts/Planer.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body{
    margin: 0;
    padding: 0;
    background-color: aliceblue;
}

.page {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

p{
  font-family: 'Rogan';
  color: #6c6f73;
}

h1, h2, h3, h4, h5, h6 ,button {
  font-family: "Planer";
}