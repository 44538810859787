.banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    z-index: 10000; /* Ensure it's on top */
  }
  
  .banner-content {
    position: relative; /* Set position to relative for absolute positioning of the close button */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%; /* 80% of the screen width */
    max-width: 800px; /* Optional max width */
    height: 80%; /* 80% of the screen height */
  }
  
  .banner-img {
    width: 100%; /* Image takes full width of banner */
    height: auto;
  }
  
  .banner-close {
    position: absolute; /* Absolute positioning */
    top: -15px; /* Move above the top border */
    right: -15px; /* Move to the right of the banner */
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    z-index: 10001; /* Ensure the close button is on top */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .banner-content {
      width: 90%; /* Adjust width for smaller screens */
      height: 70%; /* Adjust height for smaller screens */
    }
    
    .banner-close {
      font-size: 20px; /* Smaller close button */
      top: -10px; /* Adjust position */
      right: -10px; /* Adjust position */
    }
  }
  
  @media (max-width: 480px) {
    .banner-content {
      width: 95%; /* Full width on very small screens */
      height: 60%; /* Adjust height for very small screens */
    }
  
    .banner-close {
      font-size: 18px; /* Smaller close button */
      top: -5px; /* Adjust position */
      right: -5px; /* Adjust position */
    }
  }
  