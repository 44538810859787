.title-h1{
    font-size: 3rem;
    text-align: center;
}

.abtflex{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.square-btn {
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid black;
    background-color: white;
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
    transition: background-position 0.3s ease, transform 0.2s ease;
    background-size: 200% 200%;
    background-image: linear-gradient(135deg, white 50%, black 50%);
}

.square-btn:hover {
    background-position: 100% 100%;
    color: white;
    transform: translateY(-5px); /* Pop-up effect */
}

.square-btn:hover a{
    color: white;
}